import { Link, Stack, Switch, Tooltip } from "@infinitaslearning/pixel-design-system";
import React, { useMemo, useState } from "react";
import { ellipsis, multiLineEllipsis } from "@styles/helpers/util";

import ChevronDownIcon from "@infinitaslearning/pixel-icons/ChevronDown";
import ChevronUpIcon from "@infinitaslearning/pixel-icons/ChevronUp";
import { FeatureFeedbackButton } from "./feature-feedback-button";
import FeatureFlagImage from "./feature-flag-image";
import { getAnalyticsTracker } from "@lib/analytics-tracker";
import { PepTypography } from "@components/pep-typography";
import { useTranslation } from "react-i18next";
import Highlighter from "react-highlight-words";

const FeatureFlagToggle: React.FC<{
  id: string;
  value: string;
  toggle: (key: string) => void;
  showFeedback?: boolean;
  wide?: boolean;
  name: string;
  description: string;
  searchValue?: string;
}> = ({
  id,
  value,
  toggle,
  showFeedback = false,
  wide = false,
  name,
  description,
  searchValue,
}) => {
  const { t: configTranslation } = useTranslation("common", { keyPrefix: "config-toggles" });
  const [descriptions, setDescriptions] = useState<string[]>([]);
  const isDescExpanded = useMemo(() => descriptions.includes(id), [descriptions, id]);
  const valueAsBool = useMemo(() => (typeof value === "boolean" ? value : false), [value]);

  const toggleDesc = () => {
    if (descriptions.includes(id)) {
      setDescriptions(
        descriptions.filter((descKey) => {
          return descKey !== id;
        }),
      );
    } else {
      setDescriptions([...descriptions, id]);
    }
  };

  return (
    <Stack
      direction="row"
      alignItems="center"
      mb={1}
      px={2}
      py={1}
      border="1px solid"
      borderRadius={4}
      borderColor={valueAsBool ? "primary.main" : "divider"}
    >
      {wide && (
        <Stack mr={2}>
          <FeatureFlagImage id={id} altTitle={name} />
        </Stack>
      )}
      <Stack flex={1} maxWidth={"100%"}>
        <Stack direction="row" justifyContent="space-between" alignItems="center" flexWrap="nowrap">
          <Tooltip title={name} placement="top" arrow>
            <PepTypography sx={{ ...ellipsis, textWrap: "auto" }}>
              {searchValue ? (
                <Highlighter
                  searchWords={searchValue.split(" ")}
                  autoEscape
                  textToHighlight={name}
                />
              ) : (
                name
              )}
            </PepTypography>
          </Tooltip>
          <Switch
            checked={valueAsBool}
            onChange={() => {
              getAnalyticsTracker()?.elementClicked({
                elementId: "feature-flag-toggle",
                type: "toggle",
                module: "feature-flag-selector",
                itemId: name,
                value: valueAsBool ? "enabled" : "disabled",
              });
              toggle(id);
            }}
            data-testid={`toggles.${id}.name`}
          />
        </Stack>
        <Stack>
          <PepTypography variant="body1Medium" sx={!isDescExpanded ? multiLineEllipsis(2) : null}>
            {searchValue ? (
              <Highlighter
                searchWords={searchValue.split(" ")}
                autoEscape
                textToHighlight={description}
              />
            ) : (
              description
            )}
          </PepTypography>
          {!wide && (
            <Link
              sx={{
                cursor: "pointer",
                fontSize: "0.9rem",
                textDecoration: "none",
              }}
              onClick={toggleDesc}
              iconEnd={isDescExpanded ? <ChevronUpIcon /> : <ChevronDownIcon />}
            >
              {configTranslation(isDescExpanded ? "readLess" : "readMore")}
            </Link>
          )}
        </Stack>
        <Stack direction="row" justifyContent="end" mt={1}>
          {showFeedback && <FeatureFeedbackButton featureId={id} />}
        </Stack>
      </Stack>
    </Stack>
  );
};

export default FeatureFlagToggle;
