import type { LefthandNavigationItem } from "./lefthand-bar";

import FileLinesIcon from "@infinitaslearning/pixel-icons/FileLines";
import MessageIcon from "@infinitaslearning/pixel-icons/Message";
import BookOpenReaderIcon from "@infinitaslearning/pixel-icons/BookOpenReader";
import BooksIcon from "@infinitaslearning/pixel-icons/Books";
import ChartSimpleIcon from "@infinitaslearning/pixel-icons/ChartSimple";
import CalendarIcon from "@infinitaslearning/pixel-icons/Calendar";
import FolderIcon from "@infinitaslearning/pixel-icons/Folder";
import HouseIcon from "@infinitaslearning/pixel-icons/House";
import PenNibIcon from "@infinitaslearning/pixel-icons/PenNib";
import UserMagnifyingGlassIcon from "@infinitaslearning/pixel-icons/UserMagnifyingGlass";
import GearIcon from "@infinitaslearning/pixel-icons/Gear";
import { useBrand } from "@utils/use-brand";
import { useFeatureFlag } from "@utils/use-feature-flag";
import { EvaluationLicenceContext } from "@components/evaluation-licence-context/evaluation-licence-context";
import { useContext, useMemo } from "react";
import { useIsUserWithoutClassroom } from "@utils/use-is-user-without-classroom";

// NOTE: labels are not translated for the time being, until the new navigation is fully validated

const scoodlePrimaryBottomNavigation: LefthandNavigationItem[] = [
  {
    href: "https://pe.scoodle.be/pe/Auteurtool",
    label: "Author tool",
    icon: <PenNibIcon fontSize="large" color="primary" />,
  },
  {
    href: "https://pe.scoodle.be/pe/Helpdesk",
    label: "Search users",
    icon: <UserMagnifyingGlassIcon fontSize="large" color="primary" />,
  },
];

const scoodleLmsNavigation: LefthandNavigationItem[] = [
  {
    href: "https://pe.scoodle.be/pe/Agenda",
    label: "Agenda",
    icon: <CalendarIcon color="primary" fontSize="large" />,
  },
  {
    href: "https://pe.scoodle.be/care",
    label: "Care",
    icon: <FolderIcon color="primary" fontSize="large" />,
  },
  {
    href: "https://pe.scoodle.be/reports",
    label: "Reports",
    icon: <FileLinesIcon color="primary" fontSize="large" />,
  },
  {
    href: "https://pe.scoodle.be/communication",
    label: "Communication",
    icon: <MessageIcon color="primary" fontSize="large" />,
  },
];

const scoodleAdmin: LefthandNavigationItem = {
  href: "https://pe.scoodle.be/bs3/schooladmin",
  label: "Admin",
  icon: <GearIcon color="primary" fontSize="large" />,
};

const getPrimaryTopNavigation = ({
  isNewTaskFlowEnabled,
  hasEvaluation,
  enableFeatureForSpecificUser,
  admin,
  extraItems = [],
}: {
  isNewTaskFlowEnabled: boolean;
  hasEvaluation: boolean;
  enableFeatureForSpecificUser: boolean;
  admin?: LefthandNavigationItem;
  extraItems?: LefthandNavigationItem[];
}): LefthandNavigationItem[] =>
  [
    {
      href: "/",
      label: "Home",
      icon: <HouseIcon color="primary" fontSize="large" />,
      isActive: (path: string) => path === "/",
    },
    {
      href: "/content/overview",
      label: "Teaching materials",
      icon: <BooksIcon color="primary" fontSize="large" />,
      isActive: (path: string) => path.startsWith("/content"),
    },
    ...(isNewTaskFlowEnabled || enableFeatureForSpecificUser
      ? [
          {
            href: "/tasks-manager",
            label: "Tasks",
            icon: <ChartSimpleIcon color="primary" fontSize="large" />,
            isActive: (path: string) => path.startsWith("/tasks-manager"),
          },
        ]
      : []),
    ...(hasEvaluation
      ? [
          {
            href: "/evaluation-overview",
            label: "Leesradar",
            icon: <BookOpenReaderIcon color="primary" fontSize="large" />,
            isActive: (path: string) => path.startsWith("/evaluation"),
            subItems: [
              {
                href: "/evaluation-overview",
                label: "Overview",
                isActive: (path: string) => path.startsWith("/evaluation-overview"),
              },
              {
                href: "/evaluation-growth",
                label: "Growth",
                isActive: (path: string) => path.startsWith("/evaluation-growth"),
              },
            ],
          },
        ]
      : []),
    ...extraItems,
    admin,
  ].filter(Boolean) as LefthandNavigationItem[];

const useGetLefthandNavigation = () => {
  const { opCo } = useBrand();
  const isNewTaskFlowEnabled = useFeatureFlag("useNewTaskPlanningFlow", false);
  const enableNewNavigation = useFeatureFlag("enableNewNavigation", false);
  const enableFeatureForSpecificUser = useFeatureFlag("enableFeatureForSpesificUser", false);
  const { hasEvaluationLicence } = useContext(EvaluationLicenceContext);
  const isTempClassroomId = useIsUserWithoutClassroom();

  const navigation = useMemo(() => {
    if (!enableNewNavigation) {
      return null;
    }
    const hasEvaluation = !!(hasEvaluationLicence && !isTempClassroomId);
    // note: this is a temporary solution as we're still validating
    if (opCo === "plantyn") {
      return {
        primaryTopNavigation: getPrimaryTopNavigation({
          isNewTaskFlowEnabled,
          hasEvaluation,
          enableFeatureForSpecificUser,
          admin: scoodleAdmin,
          extraItems: scoodleLmsNavigation,
        }),
        primaryBottomNavigation: scoodlePrimaryBottomNavigation,
      };
    }
    return {
      primaryTopNavigation: getPrimaryTopNavigation({
        isNewTaskFlowEnabled,
        hasEvaluation,
        enableFeatureForSpecificUser,
      }),
      primaryBottomNavigation: [],
    };
  }, [
    opCo,
    isNewTaskFlowEnabled,
    hasEvaluationLicence,
    isTempClassroomId,
    enableNewNavigation,
    enableFeatureForSpecificUser,
  ]);

  return navigation;
};

export default useGetLefthandNavigation;
