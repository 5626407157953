import { createWithEqualityFn as create } from "zustand/traditional";

type LeftNavStoreType = {
  isLeftNavHovered: boolean;
  toggleIsLeftNavHovered: () => void;
  setIsLeftNavHovered: (leftNavHovered: boolean) => void;
  isSecondaryNavOpen: boolean;
  toggleIsSecondaryNavOpen: () => void;
  setIsSecondaryNavOpen: (secondaryNavOpen: boolean) => void;
};

export const WIDTH_WHEN_HOVERED = 200;
export const WIDTH_WHEN_NOT_HOVERED = 64;
export const SECONDARY_NAV_WIDTH_CLOSED = 64;
export const SECONDARY_NAV_WIDTH_OPEN = 200;

const createLeftNavStore = () => {
  return create<LeftNavStoreType>((set) => ({
    isLeftNavHovered: false,
    isSecondaryNavOpen: true,
    toggleIsLeftNavHovered: () =>
      set((state) => ({
        ...state,
        isLeftNavHovered: !state.isLeftNavHovered,
      })),
    setIsLeftNavHovered: (leftNavHovered: boolean) =>
      set((state) => ({
        ...state,
        isLeftNavHovered: leftNavHovered,
      })),
    toggleIsSecondaryNavOpen: () =>
      set((state) => ({
        ...state,
        isSecondaryNavOpen: !state.isSecondaryNavOpen,
      })),
    setIsSecondaryNavOpen: (secondaryNavOpen: boolean) =>
      set((state) => ({
        ...state,
        isSecondaryNavOpen: secondaryNavOpen,
      })),
  }));
};

export const getBodyPaddingLeft = (
  isLeftNavHovered: boolean,
  hasSecondaryNav: boolean,
  isSecondaryNavOpen: boolean,
) => {
  if (!hasSecondaryNav) {
    return isLeftNavHovered ? WIDTH_WHEN_HOVERED : WIDTH_WHEN_NOT_HOVERED;
  }
  if (isSecondaryNavOpen) {
    return isLeftNavHovered
      ? WIDTH_WHEN_HOVERED + SECONDARY_NAV_WIDTH_OPEN
      : WIDTH_WHEN_NOT_HOVERED + SECONDARY_NAV_WIDTH_OPEN;
  }
  return isLeftNavHovered
    ? WIDTH_WHEN_HOVERED + SECONDARY_NAV_WIDTH_CLOSED
    : WIDTH_WHEN_NOT_HOVERED + SECONDARY_NAV_WIDTH_CLOSED;
};

export const useLeftNavStore = createLeftNavStore();
