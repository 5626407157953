import { PepTypography } from "@components/pep-typography";

import { Box } from "@infinitaslearning/pixel-design-system";
import { useLeftNavStore } from "@lib/zustand/left-nav-store";
import Link from "next/link";

type NavigationMenuItemProps = {
  href: string;
  icon: React.ReactNode;
  label: string;
  isActive?: boolean;
};

const NavigationMenuItem = ({ href, icon, label, isActive = false }: NavigationMenuItemProps) => {
  const { setIsSecondaryNavOpen } = useLeftNavStore();
  return (
    <Link href={href} passHref legacyBehavior>
      <Box
        component="a"
        onClick={() => {
          setIsSecondaryNavOpen(true);
        }}
        sx={{
          backgroundColor: isActive ? (t) => t.pixel.color.primary.container : "transparent",
          textDecoration: "none",
          display: "flex",
          borderRadius: 2,
          alignItems: "center",
          justifyContent: "flex-start",
          flexDirection: "row",
          width: "100%",
          padding: "8px 12px",
          height: 40,
          color: "primary.main",
          "&:hover": {
            textDecoration: "none",
            backgroundColor: (t) => t.pixel.color.inverseOnSurface,
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: 24,
            height: 24,
          }}
        >
          {icon}
        </Box>
        <Box>
          <PepTypography
            variant="caption"
            sx={{
              lineHeight: 1.3,
              fontSize: 12,
              ml: 1,
              textAlign: "left",
              width: 120,
              display: "block",
            }}
          >
            {label}
          </PepTypography>
        </Box>
      </Box>
    </Link>
  );
};

export default NavigationMenuItem;
