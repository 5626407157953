import * as configcat from "configcat-js-ssr";
import { getConfig, isDevelopment, isEnabled, isEnv } from "@utils/get-config";
import type { IConfigCatClient } from "configcat-common";
import type { OpCo } from "@utils/use-brand";

const configCatClient = new Map<OpCo, IConfigCatClient>();

function initConfigCatClient(opCo: OpCo) {
  let configCatKey: string | undefined = undefined;

  if (isDevelopment() && !isEnabled("NEXT_PUBLIC_OVERRIDE_OPCO")) {
    configCatKey = getConfig("NEXT_PUBLIC_CONFIGCAT_KEY_DEV");
  } else if (isEnv("staging")) {
    configCatKey = getConfig("NEXT_PUBLIC_CONFIGCAT_KEY_STAGING");
  } else {
    switch (opCo) {
      case "liber":
        configCatKey = getConfig("NEXT_PUBLIC_CONFIGCAT_KEY_LIBER");
        break;
      case "plantyn":
        configCatKey = getConfig("NEXT_PUBLIC_CONFIGCAT_KEY_PLANTYN");
        break;
      // "noordhoff":
      default:
        configCatKey = getConfig("NEXT_PUBLIC_CONFIGCAT_KEY_JUNIORCLOUD");
        break;
    }
  }

  if (!configCatKey) {
    throw `Missing ConfigCatKey for ${opCo}`;
  }

  const client = configcat.getClient(
    configCatKey,
    configcat.PollingMode.LazyLoad,
    process.env.NODE_ENV === "development"
      ? {}
      : {
          cacheTimeToLiveSeconds: 600,
        },
  );

  configCatClient.set(opCo, client);

  return client || null;
}

export function getConfigCatClient(opCo: OpCo): IConfigCatClient | null {
  const opCoClient = configCatClient.get(opCo);

  if (!opCoClient) {
    return initConfigCatClient(opCo);
  }

  return opCoClient;
}
