import type * as z from "zod";
import { useContext, useMemo } from "react";

import { UserContext } from "@components/user-context/user-context";
import { jsonSafeParse } from "@utils/jsonSafeParse";

export const FEATURE_FLAGS = [
  "showPupilsPlatformLink",
  "isEvaluationDisabled",
  "showdisabledebookbutton",
  "enableeasyrapportthirdparty",
  "summativeTestsResults",
  "isDiffRouteWithDomainActive",
  "domainDiffRouteMethodsList",
  "showChapterInsights",
  "hidenorm",
  "useSyncAssignMaxScore",
  "showContentFeedback",
  "showChapterResultsSubDomainSwitch",
  "shouldDisplayNewsContentInHome",
  "showAutomatedTaskNote",
  "showLearningUnitInsights",
  "showLearningUnitResultsLearningGoalSwitch",
  "showYeargroupAnalysePage",
  "showDebugbuttonOnPdfviewer",
  "displayTextInteractionButtonPDF",
  "useDrillPlayer",
  "umcLessonPreparation",
  "umcLessonDashboard",
  "useLessonFinder",
  "displayPackageVersion",
  "useSlimStampenDrillingPlayer",
  "useAzureTTS",
  "disablePublishingFeatureMethodsArray",
  "hidePrimaryNavButtons",
  "newFolioQuestionsExclusionMethodList",
  "useNewTaskPlanningFlow",
  "useTallyFeedbackForm",
  "tallyFeedbackformId",
  "ebookNavigationToggle",
  "rltShowFeedbackBar",
  "tallyFormNps",
  "tallyFormCSATQ",
  "tallyFormCSATU",
  "tallyFormFolioDashboard",
  "gammaWaveEnabled",
  "useNewFolioDashboard",
  "disableDigitalEbookThumbnails",
  "viewPlayerInteractionsPage",
  "enableResultAttemptHistory",
  "enablePageAnnotations",
  "enableTrafficLight",
  "enableTimer",
  "enableCalculator",
  "enableClock",
  "enableMoney",
  "enableNumberLine",
  "enableNotepad",
  "enableRuler",
  "enableCompass",
  "enableProtractor",
  "enableTextToSpeech",
  "useNewTaskPlannerDesign",
  "enableNewNavigation",
  "showOnboardingTourButton",
  "enableFeatureForSpesificUser",
  "usetifulToken",
] as const;

export type FeatureFlag = (typeof FEATURE_FLAGS)[number];

export const useFeatureFlag = <T>(featureName: FeatureFlag, defaultValue: T): T => {
  const userContext = useContext(UserContext);

  const value = useMemo(() => {
    const featureFlagValue = userContext?.features?.find((x) => x.key === featureName)?.value;
    return featureFlagValue ?? defaultValue;
  }, [userContext, featureName, defaultValue]);

  return value;
};

export const useFeatureFlags = <T extends FeatureFlag>(featureNames: readonly T[]) => {
  const userContext = useContext(UserContext);

  const value = useMemo(() => {
    const featureFlagValues = featureNames.map((featureName) => {
      const value = userContext?.features?.find((x) => x.key === featureName)?.value;
      const parsedValue = value === undefined || value === null ? undefined : Boolean(value);
      return [featureName, parsedValue];
    });
    return Object.fromEntries(featureFlagValues) as Record<T, boolean | undefined>;
  }, [userContext, featureNames]);

  return value;
};

export const useJsonFeatureFlag = <T extends z.ZodTypeAny>(
  featureFlag: FeatureFlag,
  zodDefinition: T,
): z.infer<T> | undefined => {
  const setting = useFeatureFlag(featureFlag, "");
  const result = useMemo(() => {
    const { success, json: result } = jsonSafeParse(setting);
    if (success) {
      const parseResult = zodDefinition.safeParse(result);
      if (parseResult.error) {
        return undefined;
      }
      return parseResult.data;
    }

    return undefined;
  }, [zodDefinition, setting]);
  return result;
};
